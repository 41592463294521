import { ComponentType } from 'react';
import { connect, GetProps, InferableComponentEnhancerWithProps, MapDispatchToPropsParam, MapStateToProps, Matching } from 'react-redux';
import { mapResources } from 'app/i18n/resources';
import { combineSelectors } from './selectors/selector-utils';

export interface ResourceProps<K extends ResourceKey> {
  resources: Pick<Resources, K>;
}

export const ComponentConnectorFactory = <
  TOwnProps = {},
  TStateProps = {},
  TDispatchProps = {},
  TResourceKeys extends ResourceKey = any,
>() => {
  type TResourceProps = ResourceProps<TResourceKeys>;

  class ComponentConnectorBuilder {
    private _stateMap: MapStateToProps<TStateProps, TOwnProps, NavCarrierState>;
    private _dispatchMap: MapDispatchToPropsParam<TDispatchProps, TOwnProps>;
    private _resourceStateMap: MapStateToProps<TResourceProps, TOwnProps, NavCarrierState>;
    private _combinedStateMap: MapStateToProps<TStateProps & TResourceProps, TOwnProps, NavCarrierState>;

    private assertStateMapNotDefined() {
      if (this._stateMap) {
        throw new Error('State-to-props selector has already been defined.  Cannot define more than once!');
      }
    }
    private assertDispatchMapNotDefined() {
      if (this._dispatchMap) {
        throw new Error('Dispatch-to-props map has already been defined.  Cannot define more than once!');
      }
    }

    combineStateSelectors = <TInnerStateProps extends TStateProps>(...selectors: Selector<NavCarrierState, Partial<TInnerStateProps>, TOwnProps>[]) => {
      this.assertStateMapNotDefined();
      this._stateMap = combineSelectors(...selectors);
      return this;
    };

    mapStateToProps<TInnerStateProps extends TStateProps>(stateMap: MapStateToProps<TInnerStateProps, TOwnProps, NavCarrierState>): ComponentConnectorBuilder {
      this.assertStateMapNotDefined();
      this._stateMap = stateMap;
      return this;
    }

    mapDispatchToProps<TInnerDispatchProps extends TDispatchProps>(dispatchMap: TInnerDispatchProps): ComponentConnectorBuilder {
      this.assertDispatchMapNotDefined();
      this._dispatchMap = dispatchMap;
      return this;
    }

    withResources<K extends TResourceKeys>(...resourceKeys: K[]): ComponentConnectorBuilder {
      type ResourcePropsMapper = MapStateToProps<ResourceProps<K>, TOwnProps, NavCarrierState>;
      this._resourceStateMap = (s => ({ resources: mapResources(s.resources, ...resourceKeys) })) as any;
      return this;
    }
    connect = <C extends ComponentType<Matching<TInjected, GetProps<C>>>, TInjected = TDispatchProps & TStateProps & TResourceProps>(concreteImpl: C) => {
      if (!this._stateMap && !this._dispatchMap && !this._resourceStateMap) {
        return concreteImpl as any;
      }

      type TMergedStateProps = TStateProps & TResourceProps;
      this._combinedStateMap = combineStateMaps<TMergedStateProps, TOwnProps>(this._stateMap, this._resourceStateMap);

      const connector: InferableComponentEnhancerWithProps<TInjected, TOwnProps> =
        connect<TMergedStateProps, TDispatchProps, TOwnProps, NavCarrierState>(this._combinedStateMap, this._dispatchMap);

      return connector(concreteImpl);
    }
  }

  return new ComponentConnectorBuilder();
};

const combineStateMaps = <ConnectStateProps, OwnProps>(...maps): MapStateToProps<ConnectStateProps, OwnProps, NavCarrierState> =>
  (state, ownProps) =>
    maps.reduce((response, map) => map ? ({ ...response, ...map(state, ownProps) }) : response, {});
