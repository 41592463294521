import { MapStateToProps } from 'react-redux';

const pick = <T, K extends keyof T>(obj: T, key: K): Pick<T, K> => {
  const response = {} as Pick<T, K>;
  response[key] = obj[key];
  return response;
};

export const selectByKey = <State extends {}, K extends keyof State>(key: K) => (state: State): { [P in K]: State[P] } => pick(state, key);

export const combineSelectors: CombineSelectors = <TProps, TOwnProps>(...selectors) => (state: NavCarrierState, ownProps: TOwnProps) => {
  return selectors.reduce((mappedState, selector) => {
    return Object.assign(mappedState, selector(state, ownProps));
  }, {}) as TProps;
};

interface CombineSelectors {
  <TProps = {}, TOwnProps = {}>(...selectors: ((state: NavCarrierState, ownProps: TOwnProps) => Partial<TProps>)[])
    : MapStateToProps<TProps, TOwnProps, NavCarrierState>;
}
